import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
  Router
} from '@angular/router';
// import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
  		// public toastController: ToastController
	  ) {}

  	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		  const token = 'YWtidWlsZHRyYWRlIDJiM2VlMTQ4Njc5MmJjZTlkYjJkNmQ2YTI4OTZjYjU2LXlGQnpLeTNYMWNhQ2R3cm1TWVJGbm1jb3hrUXBQVHVVOTJkVVpSZmlPNmpCYldNVng5';
		
		//   const authKey = localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '';
		let authKey:any ;
		if(localStorage.getItem('auth_token')){
			authKey = localStorage.getItem('auth_token');
		}else{
			authKey = '';
		}

		const authReq = request.clone({
		    headers: new HttpHeaders({
		      		'Content-Type':  'application/json',
		      		'Api-Key': token,
          	  		'Auth-Key' : authKey
		    })
		});

		  console.log('Intercepted HTTP call', authReq);

		  return next.handle(authReq);

		  // if (token) {
		  //   request = request.clone({
		  //     setHeaders: {
		  //       'Api-Key': token
		  //     }
		  //   });
		  // }

		  // if (!request.headers.has('Content-Type')) {
		  //   request = request.clone({
		  //     setHeaders: {
		  //       'content-type': 'application/json'
		  //     }
		  //   });
		  // }

		  // request = request.clone({
		  //   headers: request.headers.set('Api-Key', token)
		  // });

		  // return next.handle(request).pipe(
		  //   map((event: HttpEvent<any>) => {
		  //     if (event instanceof HttpResponse) {
		  //       console.log('event--->>>', event);
		  //     }
		  //     return event;
		  //   }),
		  //   catchError((error: HttpErrorResponse) => {
		  //     if (error.status === 401) {
		  //       if (error.error.success === false) {
		  //         this.presentToast('Login failed');
		  //       } else {
		  //         this.router.navigate(['login']);
		  //       }
		  //     }
		  //     return throwError(error);
		  //   }));
	}

	// async presentToast(msg:any) {
	//   const toast = await this.toastController.create({
	//     message: msg,
	//     duration: 2000,
	//     position: 'top'
	//   });
	//   toast.present();
	// }
}
