<mat-tab-group headerPosition="below" (selectedTabChange)="navigate($event)" [selectedIndex]="selectedIndex">
  <mat-tab *ngIf="auth()" label="Account" (click)="navigate('/home')"> 
   <ng-template mat-tab-label class="tab-label">
      <img class="in-active" src="assets/images/watchlist-gray.png">
      <img class="active" src="assets/images/watchlist-color.png">

      Watchlist
    </ng-template>
   
  </mat-tab> 

  <mat-tab *ngIf="auth()" label="Account"> 
   <ng-template mat-tab-label class="tab-label">
      <img class="in-active" src="assets/images/order-gray.png">
      <img class="active" src="assets/images/order-color.png">
      
      Orders
    </ng-template>
   
  </mat-tab> 

  <mat-tab *ngIf="auth()" label="Account"> 
   <ng-template mat-tab-label class="tab-label">
      <img class="in-active" src="assets/images/portfolio-gray.png">
      <img class="active" src="assets/images/portfolio-color.png">

      Portfolio
    </ng-template>
   
  </mat-tab> 

  <!-- <mat-tab *ngIf="auth()" label="Account"> 
   <ng-template mat-tab-label class="tab-label">
      <img class="in-active" src="assets/images/funds-gray.png">
      <img class="active" src="assets/images/funds-color.png">

      Limit
    </ng-template>
   
  </mat-tab> 
 -->
  <mat-tab *ngIf="auth()" label="Account"> 
   <ng-template mat-tab-label class="tab-label">
      <img class="in-active" src="assets/images/account-gray.png">
      <img class="active" src="assets/images/account-color.png">

      Account
    </ng-template>
   
  </mat-tab> 

  
</mat-tab-group>