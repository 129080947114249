<div class="closebtn">
  <span (click)="onNoClick()" class="material-icons cross">clear</span>
</div>

<!-- verification -->
<div *ngIf="content=='verification'">
  <div class="heading">
    <h3>Varification</h3>
    <p>OTP sent to abc@yopmail.com</p>
  </div>
  <div class="formarea">
    <form class="signup-form">
      <mat-form-field class="form-field">
        <mat-label>Enter OTP</mat-label>
        <input matInput placeholder="Otp" value="1234567890" />
      </mat-form-field>

      <div class="otp">
        <a href="#" class="">Resend OTP</a>
      </div>
      <div class="submit">
        <button
          type="submit"
          (click)="onNoClick()"
          routerLink="/login"
          class="submitbtn"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>

<!--Enable touch-->
<div *ngIf="content=='enabletouch'">
  <div class="heading">
    <h3 style="padding-bottom: 20px">Enable Touch Id <br /></h3>
    <img class="header-img" src="assets/images/touch.PNG" />
  </div>
  <p style="text-align: center">Touch Sensor</p>
</div>

<!--Enable touch1-->
<div *ngIf="content=='enabletouch1'">
  <div class="heading">
    <h3 style="padding-bottom: 20px">Enable Sensor to login <br /></h3>
    <img class="header-img" src="assets/images/touch1.PNG" />
  </div>
  <p style="text-align: center">Switch Account</p>
</div>

<!--Enable Face-->
<div *ngIf="content=='enableface'">
  <div class="heading">
    <h3 style="padding-bottom: 20px">Enable Face Id <br /></h3>
    <img class="header-img" src="assets/images/face1.PNG" />
  </div>
  <p style="text-align: center">Show your face</p>
</div>

<!--Enable Face-->
<div *ngIf="content=='enableface1'">
  <div class="heading">
    <h3 style="padding-bottom: 20px">Authenticate with your Face <br /></h3>
    <img class="header-img" src="assets/images/face.PNG" />
  </div>
  <p style="text-align: center">Switch Account</p>
</div>
<!-- TPIN Success -->
<div *ngIf="content=='tpinsuccess'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>TPIN Created Successfully</h3>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        routerLink="/login/tpin"
        (click)="onNoClick()"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!--Password changed-->
<div *ngIf="content=='passwordchange'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>
      Password has been <br />
      Successfully Changed
    </h3>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        routerLink="/login"
        (click)="onNoClick()"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!--Password changed-->
<div *ngIf="content=='newpassword'">
  <div class="heading">
    <img class="header-img" src="assets/images/sms.PNG" />
    <h3>
      New password has been sent <br />
      on your Email
    </h3>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="onNoClick()"
        routerLink="/login/changepwd"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!--Account locked-->
<div *ngIf="content=='accountlocked'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>* * * * * * * *</h3>
    <h3>Account Locked<br /></h3>
    <p>Kindly set a new password</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--Account locked-->
<div *ngIf="content=='accountlocked1'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>* * * * * * * *</h3>
    <h3>Account Locked<br /></h3>
    <p>Kindly unlock your current</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--incorrect password-->
<div *ngIf="content=='incorrectpassword'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>* * * * * * * *</h3>
    <h3>Incorrect Password<br /></h3>
    <p>Kindly re-enter new password</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--Incorrect clientId-->
<div *ngIf="content=='incorrectclientId'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>
      Incorrect Client ID / Mobile<br />
      No / Email ID
    </h3>
    <p>Please Check and try again</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--Account Inactive -->
<div *ngIf="content=='accountinactive'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>
      Your account is inactive. <br />
      Kindly reactivate your account
    </h3>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--Incorrect PAN-->
<div *ngIf="content=='incorrectPAN'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>Incorrect PAN</h3>
    <p>Please Check and try again</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--Incorrect TPIN-->
<div *ngIf="content=='incorrectTPIN'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>Incorrect TPIN</h3>
    <p>Please Check and try again</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!--TPIN guidelines-->
<div *ngIf="content=='TPINguide'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>TPIN guidelines <br /></h3>
    <p>TPIN should be of 6 digits only</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        routerLink="login/tpin"
        (click)="onNoClick()"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content=='passwordguidelines'">
  <div class="heading">
    <h3>Password Guidelines</h3>
    <ul style="margin-right: 15%; text-align: justify">
      <li>
        The password should be Alpha numeric and include a special character.
      </li>
      <li>The password should be 8 - 12 characters long.</li>
      <li>The password is case sensitive.</li>
      <li>The new password cannot be same as your last 3 passwords.</li>
    </ul>
  </div>
</div>

<!-- TPIN not match-->
<div *ngIf="content=='TPINnotmatch'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>TPIN does not match</h3>
    <p>
      Please Check your set TPIN and <br />
      confirm TPIN and try again
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Password not match-->
<div *ngIf="content=='passwordnotmatch'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>Password does not match</h3>
    <p>
      Please Check your set password and <br />
      confirm password and try again
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Password not guidlness-->
<div *ngIf="content=='passwordnotguidelines'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>
      Password does not meet <br />
      guidelines
    </h3>
    <p>Check guidelines and try again</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!-- touch Id and Face Id not match-->
<div *ngIf="content=='facetouchnotmatch'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />

    <h3>
      Touch ID / Face ID <br />
      does not match
    </h3>
    <p>Please try again</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content == 'multipleID'">
  <div class="heading">
    <h3>
      Select an <br />
      account for login
    </h3>
  </div>
  <mat-radio-group
    aria-label="Select an option"
    color="primary"
    class="example-radio-group"
  >
    <mat-radio-button example-radio-button value="1">
      ClientId 12345</mat-radio-button
    >
    <hr class="hr" />
    <mat-radio-button example-radio-button value="2">
      ClientId 23456</mat-radio-button
    >
    <hr class="hr" />
  </mat-radio-group>

  <div class="formarea">
    <form class="signup-form">
      <div class="submit">
        <button type="submit" (click)="onNoClick()" class="submitbtn">
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="content=='tnc'">
  <div class="heading">
    <h2 class="text-center" style="color: var(--font-color)">T&Cs</h2>
  </div>
  <div class="content">
    <p>Lorem ipsum dolor sit amet, consectetur</p>
    <p>
      adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
    </p>
    <p>
      laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur.
    </p>
    <p>Excepteur sint occaecat cupidatat non</p>
    <p>proident, sunt in culpa</p>
  </div>
</div>

<!-- Declaration -->
<div *ngIf="content=='declaration'">
  <div class="heading">
    <h3>Declaration</h3>
    <p>
      Please specify to whom the changed details belong. Select <b>‘Self’</b> if
      it’s it’s yours
    </p>
  </div>
  <div class="formarea">
    <form class="declaration-form">
      <mat-radio-group
        aria-labelledby="declaration-radio-group-label"
        class="declaration-radio-group"
        [(ngModel)]="declarationType"
      >
        <mat-radio-button
          class="declaration-radio-button"
          *ngFor="let declare of declaration"
          [value]="declare"
        >
          {{declare}}
        </mat-radio-button>
      </mat-radio-group>

      <div class="submit">
        <button
          type="submit"
          (click)="navigate('/bank-details')"
          class="submitbtn"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Request received -->
<div *ngIf="content=='request-received'">
  <div class="heading">
    <img class="header-img" src="assets/images/check_sign.png" />
    <h3>We have received <br />your request</h3>
    <p>The changes will reflect after one working day</p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Code Applied -->
<div *ngIf="content=='codeapply'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>Voucher applied</h3>
    <p style="color: var(--font-color)">
      Free a/c opening. Incase of any discounts due to <br />promocode, the
      total payableamount will <br />
      change and customer will only make balance <br />
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="navigate('total-charges/tc-remove')"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Code Applied -->
<div *ngIf="content=='vouch'">
  <div class="heading">
    <h3>Voucher or Promo Code</h3>
    <p style="color: var(--font-color)">
      Free a/c opening. Incase of any discounts due to <br />promocode, the
      total payableamount will <br />
      change and customer will only make balance <br />
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button type="submit" (click)="onNoClick()" class="submitbtn">
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Sorry code not applied -->

<div *ngIf="content=='sorrycode'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />
    <h3>Sorry</h3>
    <p style="color: var(--font-color)">
      Code does not exist <br />
      Please Check and try again
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="onNoClick()"
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!-- Remove coupon -->
<div *ngIf="content=='removeCoupon'">
  <div class="heading">
    <img class="header-img" src="assets/images/question.png" />
    <h3>
      Are you sure you want to <br />
      remove promo code
    </h3>
  </div>
  <div class="formarea">
    <div id="container">
      <div class="button_yes">
        <p style="margin-bottom: -17px">No</p>
        <button class="submityes" (click)="navigate('/total-charges')">
          Cancel
        </button>
      </div>
      <div class="button_no">
        <p style="margin-bottom: -17px">Yes</p>
        <button class="submitno" (click)="navigate('/total-charges/tc')">
          Remove
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Voucher info -->
<div *ngIf="content=='codeapplied'">
  <div class="heading">
    <h3>Voucher applied</h3>
    <p style="color: var(--font-color)">
      Free a/c opening. Incase of any discounts due to <br />promocode, the
      total payableamount will <br />
      change and customer will only make balance <br />
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="navigate('total-charges/tc-remove')"
        class="submitbtn"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!-- ifsc -->
<div *ngIf="content=='ifsc'">
  <div class="heading">
    <h3>IFSC Search</h3>
  </div>
  <div class="formarea">
    <form class="ifsc-search-form">
      <mat-form-field class="form-field">
        <mat-label>Bank Name</mat-label>
        <input matInput placeholder="Bank Name" value="ABCDE1234A" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>State </mat-label>
        <input matInput placeholder="State" value="28/12/1990" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" value="123456789012345" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>Branch</mat-label>
        <input matInput placeholder="Branch" value="" />
      </mat-form-field>
      <div class="submit">
        <button
          type="submit"
          (click)="dismissWithAction('select-branch')"
          class="submitbtn"
        >
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Declaration -->
<div *ngIf="content=='select-branch'">
  <div class="heading">
    <h3>Select Your Branch</h3>
  </div>
  <div class="formarea">
    <form class="select-branch-form">
      <mat-radio-group
        aria-labelledby="branch-radio-group-label"
        class="branch-radio-group"
        [(ngModel)]="selectBranch"
        [ngModelOptions]="{standalone: true}"
      >
        <mat-radio-button
          class="branch-radio-button branch"
          *ngFor="let branch of branches"
          [value]="branch"
        >
          {{branch}}
        </mat-radio-button>
      </mat-radio-group>

      <div class="submit">
        <button type="submit" (click)="onNoClick()" class="submitbtn">
          Proceed
        </button>
      </div>
    </form>
  </div>
</div>

<!-- Plan Selection Confirmation -->
<div *ngIf="content=='plan-selection-confirmation'">
  <div class="heading">
    <h3>Plan Selection Confirmation</h3>
  </div>
  <div class="instruction1">
    <p class="sub-heading">
      By not selecting a subscription Plan you will be charged the following :
    </p>
    <mat-list class="plan-info">
      <mat-list-item> Equity Intraday @ 0.02% </mat-list-item>
      <mat-list-item> Equity Delivery @ 0.20% </mat-list-item>
      <mat-list-item> Equity & Currency Delivery @ 0.02%</mat-list-item>
      <mat-list-item> Equity & Currency Options @ Rs.25 per lot </mat-list-item>
    </mat-list>
  </div>
  <div class="instruction2">
    <p class="sub-heading">You miss out :</p>
    <mat-list class="miss-out">
      <mat-list-item>
        Unlimited trading in equity, F&Q, and Currency
      </mat-list-item>
      <mat-list-item>Zero* Brokerage on Delivery </mat-list-item>
      <mat-list-item> Opt-out anytime if you find a cheaper plan</mat-list-item>
    </mat-list>
    <p class="brokerage">*Brokerage at Rs 0.01 perececuted order</p>
  </div>
  <div class="submit">
    <button type="submit" (click)="onNoClick()" class="cancel">Close</button>
    <button type="submit" (click)="onNoClick()" class="confirmbtn">
      Confirm
    </button>
  </div>
</div>

<!-- bodhitree -->

<div *ngIf="content=='bodhitree'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />
    <h3>Sorry</h3>
    <p style="color: var(--font-color)">
      All Bid lots should be same. Please change and try again
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="content='bodhitree-1'"
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content=='bodhitree-1'">
  <div class="heading">
    <img class="header-img" src="assets/images/exclamation-mark.svg" />
    <h3>Sorry</h3>
    <p style="color: var(--font-color)">
      You can only bid when IPO is open for bidding by the exchange. Please try
      again later
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="navigate('/account/online-ipo/bodhitree/proceed')"
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content=='placed'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>Bid Placed</h3>
    <p style="color: var(--font-color)">
      Go to BHIM app or your mobile banking app to approve blocking of funds.
      The IPO will only be processed after your approval
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="content='placed-1'"
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content=='placed-1'">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg" />
    <h3>Bid has been cancelled successfully</h3>
    <p style="color: var(--font-color)"></p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit"
        (click)="onNoClick()"
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<div *ngIf="content=='tnc'"></div>

<div *ngIf="content=='view-pdf'" style="width: 200px; height: 500px">
  <h1>pdf</h1>
</div>

<div *ngIf="content=='view-html' " style="width: 200px; height: 500px">
  <h1>html</h1>
</div>

<div *ngIf="content=='view-more'" style="width: 200px; height: 500px">
  <h1>know-more</h1>
</div>

<!-- margin-against-holding -->

<div *ngIf=" content=='create-margin' ">
  <div class="heading">
    <img class="header-img" src="assets/images/right-sign.svg " />
    <h3>Request Recieved</h3>
    <p style="color: var(--tnc-text-color)">
      Once you approve the request from DP the margin will be given soon
    </p>
  </div>
  <div class="formarea">
    <div class="submit">
      <button
        type="submit "
        (click)="onNoClick() "
        class="submitbtn"
        class="btn-new"
      >
        Okay
      </button>
    </div>
  </div>
</div>

<!-- my-trading-plan -->

<div *ngIf="content=='confirmation' ">
  <div class="heading">
    <!-- <img class="header-img " src="assets/images/right-sign.svg "> -->
    <h3>Confirmation</h3>
    <p style="color: var(--dark-gray)">
      You have chosen to opt out of the subscription plan
    </p>
  </div>
  <div class="formarea plan-button">
    <div class="submit">
      <button
        type="submit "
        (click)="content='confirmation_1' "
        class="submitbtn"
        class="btn-dark"
        style="outline: none"
      >
        UNSUBSCRIBE NOW
      </button>
    </div>
    <div class="submit">
      <button
        type="submit "
        (click)="content='confirmation_1' "
        class="submitbtn"
        class="btn-dark"
        style="outline: none"
      >
        UNSUBSCRIBE ON EXPIRY
      </button>
    </div>
  </div>
</div>
<div *ngIf="content=='confirmation_1' ">
  <div class="heading">
    <!-- <img class="header-img " src="assets/images/right-sign.svg "> -->
    <h3>Confirmation</h3>
    <p style="color: var(--dark-gray)">
      You have chosen to opt out of the subscription plan
    </p>
  </div>
  <div class="formarea plan-button">
    <div class="submit">
      <button type="submit " (click)="onNoClick() " class="close">CLOSE</button>
    </div>
  </div>
</div>
