import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { TabsComponent } from './components/tabs/tabs.component';
import { DialogDetailModule } from './components/auth-partial/dialog-detail.module';
// import { SearchModule } from './components/multiselect-autocomplete-example/multiselect-autocomplete-example.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { SwipeAngularListModule } from 'swipe-angular-list';
import { MarketSectionComponent } from './market-section/market-section.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar'
// import 'hammerjs';
// import { HttpClientModule } from '@angular/common/http';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './interseptors/token.interceptor';


@NgModule({
  declarations: [AppComponent, TabsComponent, MarketSectionComponent],
  imports: [
    BrowserModule,
    SwipeAngularListModule,
    HammerModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    // SearchModule,
    DialogDetailModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSnackBarModule,
    HttpClientModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },{provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}}],
  bootstrap: [AppComponent],
})
export class AppModule {}
