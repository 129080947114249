import { Component } from '@angular/core';
import { CommonService } from './common.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aman-angular-project';
  constructor(public commonService: CommonService) { }

  auth(){
  	if(localStorage.getItem('isLogin')){
  		return true;
  	}else{
  		return false;
  	}
  }
}
