import { MarketSectionModule } from './market-section/market-section.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiService } from './api.service'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [ApiService]
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
    canActivate: [ApiService]
  },
  {
    path: 'view-chart',
    loadChildren: () =>
      import('./view-chart/view-chart.module').then((m) => m.ViewChartModule),
  },
  {
    path: 'buy-sell',
    loadChildren: () =>
      import('./buy-sell/buy-sell.module').then((m) => m.BuySellModule),
      canActivate: [ApiService]
  },
  {
    path: 'buy-sell-modify',
    loadChildren: () =>
      import('./buy-sell-modify/buy-sell-modify.module').then((m) => m.BuySellModifyModule),
      canActivate: [ApiService]
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderModule),
      canActivate: [ApiService]
  },
  {
    path: 'portfolio',
    loadChildren: () =>
      import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
      canActivate: [ApiService]
  },
  {
    path: 'funds',
    loadChildren: () =>
      import('./funds/funds.module').then((m) => m.FundsModule),
      canActivate: [ApiService]
  },
  {
    path: 'search',
    loadChildren: () =>
      import(
        './components/multiselect-autocomplete/multiselect-autocomplete.component'
      ).then((m) => m.MultiselectAutocompleteModule),
      canActivate: [ApiService]
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login/createtpin',
    loadChildren: () =>
      import('./login/create-tpin/create-tpin.module').then(
        (m) => m.CreateTpinModule
      ),
  },

  {
    path: 'login/tpin',
    loadChildren: () =>
      import('./login/tpin/tpin.module').then((m) => m.TpinModule),
  },

  {
    path: 'forgotpwd',
    loadChildren: () =>
      import('./login/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'login/changepwd',
    loadChildren: () =>
      import('./login/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'bank-details',
    loadChildren: () =>
      import('./signup/bank-details/bank-details.module').then(
        (m) => m.BankDetailsModule
      ),
  },
  {
    path: 'kyc',
    loadChildren: () =>
      import('./signup/kyc/kyc.module').then((m) => m.KycModule),
  },
  {
    path: 'personal-details',
    loadChildren: () =>
      import('./signup/personal-details/personal-details.module').then(
        (m) => m.PersonalDetailsModule
      ),
  },
  {
    path: 'plan-details',
    loadChildren: () =>
      import('./signup/plan-details/plan-details.module').then(
        (m) => m.PlanDetailsModule
      ),
  },
  {
    path: 'total-charges',
    loadChildren: () =>
      import('./signup/total-charges/total-charges.module').then(
        (m) => m.TotalChargesModule
      ),
  },
  {
    path: 'total-charges/tc-remove',
    loadChildren: () =>
      import('./signup/total-charges/tc-remove/tc-remove.module').then(
        (m) => m.TcRemoveModule
      ),
  },
  {
    path: 'total-charges/tc',
    loadChildren: () =>
      import('./signup/total-charges/tc/tc.module').then((m) => m.TcModule),
  },

  {
    path: 'total-charges/account-charges',
    loadChildren: () =>
      import('./signup/total-charges/account-open/account-open.module').then(
        (m) => m.AccountOpenModule
      ),
  },
  {
    path: 'total-charges/ac',
    loadChildren: () =>
      import('./signup/total-charges/ac/ac.module').then((m) => m.AcModule),
  },
  {
    path: 'upload-documents',
    loadChildren: () =>
      import('./signup/upload-documents/upload-documents.module').then(
        (m) => m.UploadDocumentsModule
      ),
  },
  {
    path: 'e-sign',
    loadChildren: () =>
      import('./signup/sign/sign.module').then((m) => m.SignModule),
  },
  {
    path: 'thank-you',
    loadChildren: () =>
      import('./signup/thank-you/thank-you.module').then(
        (m) => m.ThankYouModule
      ),
  },
  {
    path: 'confirm-order',
    loadChildren: () =>
      import('./buy-sell/confirm-order/confirm-order.module').then(
        (m) => m.ConfirmOrderModule
      ),
  },
  {
    path: 'market-order',
    loadChildren: () =>
      import('./marketorder/marketorder.module').then(
        (m) => m.MarketorderModule
      ),
  },
  {
    path: 'tradeinteraction',
    loadChildren: () =>
      import('./tradeinteraction/tradeinteraction.module').then(
        (m) => m.TradeinteractionModule
      ),
  },
  {
    path: 'buy-sell/success',
    loadChildren: () =>
      import('./buy-sell/success/success.module').then((m) => m.SuccessModule),
  },
  {
    path: 'buy-sell/fail',
    loadChildren: () =>
      import('./buy-sell/fail/fail.module').then((m) => m.FailModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'funds/add-funds',
    loadChildren: () =>
      import('./funds/add-funds/add-funds.module').then(
        (m) => m.AddFundsModule
      ),
  },
  {
    path: 'funds/withdraw-funds',
    loadChildren: () =>
      import('./funds/withdraw-funds/withdraw-funds.module').then(
        (m) => m.WithdrawFundsModule
      ),
  },
  {
    path: 'account/online-ipo',
    loadChildren: () =>
      import('./account/online-ipo/online-ipo.module').then(
        (m) => m.OnlineIpoModule
      ),
  },

  {
    path: 'account/header',
    loadChildren: () =>
      import('./account/header/header.module').then((m) => m.HeaderModule),
  },

  {
    path: 'account/online-ipo/bodhitree',
    loadChildren: () =>
      import('./account/online-ipo/bodhitree/bodhitree.module').then(
        (m) => m.BodhitreeModule
      ),
  },
  {
    path: 'account/margin-against-holdings',
    loadChildren: () =>
      import(
        './account/margin-against-holdings/margin-against-holdings.module'
      ).then((m) => m.MarginAgainstHoldingsModule),
  },
  {
    path: 'account/my-trading-plan',
    loadChildren: () =>
      import('./account/my-trading-plan/my-trading-plan.module').then(
        (m) => m.MyTradingPlanModule
      ),
  },
  {
    path: 'account/my-trading-plan/subscribed',
    loadChildren: () =>
      import('./account/my-trading-plan/subscribed/subscribed.module').then(
        (m) => m.SubscribedModule
      ),
  },
  {
    path: 'account/refer-and-earn',
    loadChildren: () =>
      import('./account/refer-and-earn/refer-and-earn.module').then(
        (m) => m.ReferAndEarnModule
      ),
  },
  {
    path: 'account/refer-and-earn/invite-friends',
    loadChildren: () =>
      import(
        './account/refer-and-earn/invite-friends/invite-friends.module'
      ).then((m) => m.InviteFriendsModule),
  },
  {
    path: 'account/online-ipo/bodhitree/proceed',
    loadChildren: () =>
      import('./account/online-ipo/bodhitree/proceed/proceed.module').then(
        (m) => m.ProceedModule
      ),
  },

  {
    path: 'account/profile-picture',
    loadChildren: () =>
      import('./account/profile-picture/profile-picture.module').then(
        (m) => m.ProfilePictureModule
      ),
  },
  {
    path: 'withdraw-amount',
    loadChildren: () =>
      import('./withdraw-amount/withdraw-amount.module').then(
        (m) => m.WithdrawAmountModule
      ),
  },

  {
    path: 'account/profile-picture/edit-picture',
    loadChildren: () =>
      import('./account/profile-picture/edit-picture/edit-picture.module').then(
        (m) => m.EditPictureModule
      ),
  },
  {
    path: 'welcome/login-help',
    loadChildren: () =>
      import('./welcome/login-help/login-help.module').then(
        (m) => m.LoginHelpModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
