import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';  

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  selectedIndex:any = 0;
  constructor(private router: Router,private readonly location: Location) { }
  page:any= ['/home','/orders','/portfolio',/*'/funds',*/'/account']
  ngOnInit(): void {
    console.log(this.location.path())
    if(this.location.path().indexOf('home') > -1){
      this.selectedIndex = 0;
    }else if(this.location.path().indexOf('orders') > -1){
      this.selectedIndex = 1;
    }else if(this.location.path().indexOf('portfolio') > -1){
      this.selectedIndex = 2;
    }else if(this.location.path().indexOf('account') > -1){
      this.selectedIndex = 3;
    }
  }

  navigate(event: any){
  	// alert(page)
  	this.router.navigateByUrl(this.page[event.index])
    this.selectedIndex = event.index;
  }
  auth(){
    if(localStorage.getItem('isLogin')){
      return true;
    }else{
      return false;
    }
  }
}
