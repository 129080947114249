<div style="overflow: auto;" [ngClass]="{'dark-theme':commonService.theme == '2','dark-primary':commonService.theme == '3','colored':commonService.theme == '4'}">
    <div class="notification-drawer">
    <input type="checkbox" id="secret-checkbox" />
    <div class="toolbar" style="z-index: 9;visibility: hidden;">
        <label for="secret-checkbox">
		<span class="toolbar-heading trigger">
			<div style="width: 70px;height: 5px; border-radius: 5px; background-color: #828288;margin-top: -16px;"></div>
		</span>
	</label>
    </div>
    <div class="notification-menu">

        <!-- <app-market-section style="max-height: 80vh;overflow: auto; background-color: var(--main-background);" ></app-market-section> -->
        <input type="checkbox" id="secret-checkbox" />
        <div class="toolbar" style="top: unset;bottom: 0px;">
            <label for="secret-checkbox">
            <span class="toolbar-heading trigger">
                <div style="width: 70px;height: 5px; border-radius: 5px; background-color: var(--dropdown-button-color);margin-top: -16px;"></div>
            </span>
        </label>
        </div>
    </div>

</div>
<router-outlet></router-outlet>


<app-tabs></app-tabs>
</div>