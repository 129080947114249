<div class="main-div">
    <p class="main-heading">Indicies</p>
    <div class="data" style="margin-top: 30px;">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>
                &nbsp; &nbsp;NIFTY

        </section>
        <p>11500</p>
        <p class="green">+125.50 (+1.55%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;Sensex

        </section>
        <p>40450</p>
        <p class="green">+325.55 (+1.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;Banknifty

        </section>
        <p>45250</p>
        <p class="red">-125.50 (-125.55%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <div class="data">
        <section>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8662 1.89154C11.6887 1.71367 11.4013 1.71321 11.2233 1.89032L7.77191 5.32773L6.77448 4.3303C6.5969 4.15288 6.30934 4.15272 6.13176 4.3303L2.08631 8.37577C1.90873 8.55319 1.90873 8.84107 2.08631 9.0185C2.17495 9.10728 2.29132 9.15167 2.40769 9.15167C2.52389 9.15167 2.64042 9.10728 2.72904 9.0185L6.45313 5.29457L7.44995 6.29122C7.62737 6.46864 7.9142 6.46881 8.09207 6.29197L11.8648 2.53441C12.0427 2.35729 12.0433 2.06941 11.8662 1.89154Z" fill="var(--font-color)"/>
                <path d="M11.5303 10.5449H0.909092V0.999468C0.909092 0.748406 0.705608 0.544922 0.454546 0.544922C0.203484 0.544922 0 0.748406 0 0.999468V10.9995C0 11.2505 0.203484 11.454 0.454546 11.454H11.5303C11.7814 11.454 11.9848 11.2505 11.9848 10.9995C11.9848 10.7484 11.7814 10.5449 11.5303 10.5449Z" fill="var(--font-color)"/>
                <path d="M11.5472 1.74219H8.13814C7.88708 1.74219 7.68359 1.94567 7.68359 2.19673C7.68359 2.4478 7.88708 2.65128 8.13814 2.65128H11.0927V5.60597C11.0927 5.85687 11.2962 6.06052 11.5472 6.06052C11.7983 6.06052 12.0018 5.85687 12.0018 5.60581V2.19673C12.0018 1.94567 11.7983 1.74219 11.5472 1.74219Z" fill="var(--font-color)"/>
                </svg>&nbsp; &nbsp;BSE 100

        </section>
        <p>45250</p>
        <p class="green">-125.50 (+2.45%)</p>
    </div>
    <p class="view-more" style="text-decoration: underline;">View Less</p>
    <p style="text-align: left; font-size: 16px; color: var(--font-color);">Nifty</p>
    <img src="assets/images/Screenshot-chart.png" alt="" width="100%" height="300px" style="margin-top: 10px;">
    <div class="my-profile">

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <p style="font-size: 16px; color: var(--font-color);">Notifications</p>
                </mat-panel-title>

            </mat-expansion-panel-header>
            <div class="details-box" style="border-top:unset !important ;">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mollis dui, facilisis mi proin tellus. Scelerisque.</p>
                <p style="text-align: right;">02-02-2021 17:05</p>
            </div>
            <div class="details-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mollis dui, facilisis mi proin tellus. Scelerisque.</p>
                <p style="text-align: right;">02-02-2021 12:05</p>
            </div>
            <div class="details-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mollis dui, facilisis mi proin tellus. Scelerisque.</p>
                <p style="text-align: right;">02-02-2021 08:05</p>
            </div>
        </mat-expansion-panel>

    </div>