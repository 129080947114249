import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarModule,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { catchError, tap } from 'rxjs/operators';
// import { ToastController } from '@ionic/angular';
// import { AlertController } from '@ionic/angular';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
// import { AlertController, LoadingController, NavController } from '@ionic/angular';
// import { Toast } from '@ionic-native/toast/ngx';
// import {TranslateService} from '@ngx-translate/core';
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  defaultLang: any = localStorage.getItem('defaultLang') ? localStorage.getItem('defaultLang') : 'it';
  errMessage: any = "something went wrong."
// <option value="en">English</option>
//           <option value="hindi">Hindi</option>
//           <option value="bangali">Bangali</option>
//           <option value="punjabi">Punjabi</option>
//           <option value="urdu">Urdu</option>

  currentLang: any = {"en":"english","hindi":"hindi","bangali":"bangali","punjabi":"pangabi","urdu":"urdu"}
  
  commonErrorMsg:any = 'Something went wrong.';
  noInternetMsg:any = "Can't connect right now. Try again.";
  discountPrice: any=0
  // local
  // apiUrl="http://127.0.0.1:8000/api/";
  // baseUrl="http://127.0.0.1:8000/";
  // baseUrlImage="http://127.0.0.1:8000";
  
  // live
  apiUrl=" https://wealthsecurity.in/admin/public/api/";
  baseUrl=" https://wealthsecurity.in/admin/public/";
  baseUrlImage=" https://wealthsecurity.in/admin/public";

  


  userLocation: any = ''

  fcmToken: any = 'fcmtoken';
  public country: any = '';
  code_calling: any ='';
  termPageData: any = {};
  price: any = 0;
  cat: any;
  city: any;
  randomOtp: any;
  wbalance: any;
  pageContents:any;
  app_version_code: any = 8;
  cart_id: any = '';
  public cartItems: any = [];
  public subscribtion: boolean = false;
  public CurrentPlan: any = {discount_type: 'Flat', discount:'0'}
  UniqueDeviceID: any = '';
  cartQuantity:number = 0;
  public cartSubTotalAmout:number = 0;
  public cartTotalAmout:number = 0;
  public lasturl = 'home';

  public allVendorStoreList:any;
  public userProfileImage:any;
  states: any = []
  listData: any = []
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  constructor(
    public _snackBar: MatSnackBar,
      // private uniqueDeviceID: UniqueDeviceID,
      // private translate: TranslateService,
      // public navController: NavController,
      // public loadingController: LoadingController,
      private router: Router,
      // public alertController: AlertController,
      public http: HttpClient,
      // public toastController: ToastController,
      // public toast: Toast
    ) {
    // this.getAppVersion();
    // this.getPageContent(); 
    // this.cartSubTotalAmout = this.getCartSubTotal();
    // this.cartTotalAmout = this.getCartSubTotal();
    
    if(this.getUserDetail('profile_pic')){
      this.userProfileImage = this.baseUrlImage+''+this.getUserDetail('profile_pic');
    }
    
    
    // this.getCartList();
    // this.getUniqueDeviceID();

    // this.get('states').subscribe((res) => { 
     
      
    //   if(res.status){
    //     this.states = res.data;
    //   }
    // }, err => {
      
    // })
    
    // f6ERy1tFT2yO0_6Youkx4e:APA91bH5WKh2ij8RFONjZFd48FzU9DUY0laccNCe7upYrUEbWd-IF-FHSKtgdHMow3bBdSOLEtbzAEpqzOqpjNyg0LKnX94WItSqbmh-NX2Of1bc0mZblR2FOYLVK9y44o6o08dfwRqH
    // var self = this;
    // $.get( "https://ipapi.co/json", function( data ) {
    //   console.log(data);
    //   self.country = data.country;
    //   self.code_calling = data.country_calling_code;
    //   // $( ".result" ).html( data );
    //   // alert( "Load was performed." );
    // });


    // var self = this;
    // var settings = {
    //   "url": "https://api.truedata.in/getAllSymbols?segment=eq&user=tdwsp419&password=anand@419&csv=false&csvheader=true&allexpiry=false",
    //   "method": "GET",
    //   "timeout": 0,
    //   "headers": {},
    // };

    // $.ajax(settings).done(function (response: any) {
    //   console.log(response);
    //   if(response.status){
    //     self.listData = response.Records;
    //     // self.listData.concat(response.Records)
    //   }else{
    //     // alert(response.status)
    //   }
      
    // });

    // var self = this;
    // var settings = {
    //   "url": "https://api.truedata.in/getAllSymbols?segment=fut&user=tdwsp419&password=anand@419&csv=false&csvheader=true&allexpiry=false",
    //   "method": "GET",
    //   "timeout": 0,
    //   "headers": {},
    // };

    // $.ajax(settings).done(function (response: any) {
    //   console.log(response);
    //   if(response.status){
    //     // self.listData = response.Records;
    //     response.Records.forEach((ele:any)=>{
    //       self.listData.push(ele)
    //     })
    //     // self.listData.concat(response.Records)
    //   }else{
    //     // alert(response.status)
    //   }
      
    // });

    // var self = this;
    // var settings = {
    //   "url": "https://api.truedata.in/getAllSymbols?segment=fo&user=tdwsp419&password=anand@419&csv=false&csvheader=true&allexpiry=false",
    //   "method": "GET",
    //   "timeout": 0,
    //   "headers": {},
    // };

    // $.ajax(settings).done(function (response: any) {
    //   console.log(response);
    //   if(response.status){
    //     self.listData = response.Records;
    //     // response.Records.forEach((ele:any)=>{
    //     //   self.listData.push(ele)
    //     // })
    //   }else{
    //     // alert(response.status)
    //   }
      
    // });

    // var self = this;
    // var settings = {
    //   "url": "https://api.truedata.in/getAllSymbols?segment=in&user=tdwsp419&password=anand@419&csv=false&csvheader=true&allexpiry=false",
    //   "method": "GET",
    //   "timeout": 0,
    //   "headers": {},
    // };

    // $.ajax(settings).done(function (response: any) {
    //   console.log(response);
    //   if(response.status){
    //     // self.listData = response.Records;
    //     response.Records.forEach((ele:any)=>{
    //       self.listData.push(ele)
    //     })
    //   }else{
    //     // alert(response.status)
    //   }
      
    // });


    
   }

   CalculateDistance(lat1:any, lon1:any, lat2:any, lon2:any, unit='K') {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1/180;
      var radlat2 = Math.PI * lat2/180;
      var theta = lon1-lon2;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit=="K") { dist = dist * 1.609344 }
      if (unit=="N") { dist = dist * 0.8684 }
      // return dist;
      let distance = dist.toFixed(2);
      return parseFloat(distance);
    }
  }



  //  getUniqueDeviceID() {
  //   this.uniqueDeviceID.get()
  //     .then((uuid: any) => {
  //       console.log(uuid);
  //       this.UniqueDeviceID = uuid;
  //     })
  //     .catch((error: any) => {
  //       console.log(error);
  //       this.UniqueDeviceID = "Error! ${error}";
  //     });
  // }

   isLogin(){
     if(localStorage.getItem('islogin')){
       return true;
     }else{
       return false;
     }
   }

  //  getCartList(){
  //    if(localStorage.getItem('islogin')){
  //      this.post('cart_list',{user_id: this.getUserDetail('id')}).subscribe(res=>{
  //        if(res.status){
  //          this.cartItems = res.data
  //          this.cart_id = res.cart_id
  //        }else{
  //          this.cartItems = [];
  //        }
  //      })
     


  //     this.post('wallet_amount',{user_id: this.getUserDetail('id')}).subscribe((res) => { 
         
  //       if(res.status){
  //         this.wbalance = res.balance;
          
  //       }
  //     }, err => {
        
  //       this.presentToast(this.errMessage)
  //     })
  //   }
  //  }

  //  addToCart(product:any){
  //    console.log(product)
  //    product.quantity = 1;
  //     let cart = {};
  //     cart = {
  //       "product_id":product.product_id,
  //       "qty":1,
  //       "price":product.price,
  //       "user_id":this.getUserDetail('id')
  //     }
  //     if (navigator.onLine) {
  //       // this.apiService.showLoading();
  //       this.post('add_to_cart', cart).subscribe((res) => { 
  //         if(res.status){
  //           this.getCartList()
  //           this.presentToast('added to cart');
  //         }else{
  //           this.presentToast(res.message);
  //         }
  //       }, err => {
  //         // this.hideLoading();
  //         this.presentToast(this.errMessage);
  //       })
  //     }else{
  //       this.presentToast(this.noInternetMsg);
  //     }
  //  }

  //  checkProductInCart(product_id:any){
  //    // console.log(this.cartItems)
  //    if(this.cartItems.length){
  //       let index = this.cartItems.findIndex((ele:any)=>ele.product_id == product_id);
  //       // console.log(index)
  //       if(index > -1){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //    }else{
  //      return false;
  //    }
  //  }


  //  deleteProductFromCart(product_id:any){
  //    if(this.cartItems.length){
  //       let index = this.cartItems.findIndex((ele:any)=>ele.product_id == product_id);
  //       if(index > -1){
  //         this.post('remove_cart', {user_id: this.getUserDetail('id'),product_id: product_id}).subscribe((res) => { 
  //           this.presentToast(res.message)
  //           if(res.status){
  //               this.getCartList();
                
  //           }
  //         }, err => {
  //           // this.hideLoading();
  //           this.presentToast(this.errMessage)
  //         })
  //       }
  //    }
  //  }


  //  updateCart(product:any,add:any){
     
  //    if(add){
  //      console.log(product)
  //      product.quantity = parseFloat(product.quantity);
  //      product.quantity++;
  //    }else{
  //      product.quantity = parseFloat(product.quantity);
  //      if(product.quantity < 2){
  //        this.deleteProductFromCart(product.product_id)
  //      }else{
  //        product.quantity--;
  //      }
  //    }
  //    if (navigator.onLine) {
  //         var data = {
  //             "user_id":this.getUserDetail('id'),
  //             "product_id":product.product_id,
  //             "quantity": product.quantity
  //         }
  //       // this.apiService.showLoading();
  //       this.post('update_cart', data).subscribe((res) => { 
          
         
  //         this.presentToast(res.message)
  //         if(res.status){
              
  //             if(product.quantity == '0'){
  //               this.deleteProductFromCart(product.product_id)
  //             }else{
  //               this.getCartList();
  //             }
  //         }
  //       }, err => {
  //         // this.hideLoading();
  //         this.presentToast(this.errMessage)
  //       })

          
  //     }else{
  //       this.presentToast(this.noInternetMsg)
    
  //     }
  //  }


  //  changeLanguage(lang:any){
  //    console.log(lang)
  //    this.translate.setDefaultLang(lang);
  //    localStorage.setItem('defaultLang',lang)
  //    this.defaultLang = lang
     
  //  }
   

   getUserDetail(key = ''){
     let userData = localStorage.getItem('userDetail');
     let userDetail:any = [];
     if(userData){
         userDetail = JSON.parse(userData);
        if(key!=''){
          return userDetail[key];
        }else{
          return userDetail;
        }
     }else{
      return userDetail;
     }
     
   }

  //  diff_years(dt2, dt1) 
  //  {
  //    console.log(dt2)
  //    console.log(dt1)
  //   var diff =(parseFloat(dt2) - parseFloat(dt1)) / 1000;
  //    diff /= (60 * 60 * 24);
  //   return Math.abs(Math.round(diff/365.25));
     
  //  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   const isLoggedIn = localStorage.getItem('islogin'); // ... your login logic here
  //   if (!isLoggedIn) {
  //     return true;
  //   } else {
  //     this.router.navigate(['/home']);
  //     return false;
  //   }
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = localStorage.getItem('islogin'); // ... your login logic here
    if (isLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


  // getAppVersion(){
  //   this.http.get<any>(this.apiUrl + 'getAppVersion').subscribe(response=>{
  //     let res = response.data;
  //     if(parseFloat(res.app_version_code) > parseFloat(this.app_version_code)){
  //       if(res.ForceUpdate=='true'){
  //         this.forceUpdatePopup(res.UpdateMessage);
  //       }else{
  //         if(localStorage.getItem('UpdationDate')){
  //           if(parseFloat(localStorage.getItem('UpdationDate')) < parseFloat(res.UpdationDate)){
  //             this.normalUpdate(res.UpdateMessage,res.UpdationDate)
  //           }
  //         }else{
  //           this.normalUpdate(res.UpdateMessage,res.UpdationDate)
  //         }
          
  //       }
  //     }
  //   },err=>{

  //   })
  // }

  // async normalUpdate(UpdateMessage,UpdationDate) {
  //   const alert = await this.alertController.create({
  //     header: 'New Update!',
  //     message: UpdateMessage,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {
  //           localStorage.setItem('UpdationDate',UpdationDate);
  //           console.log('Confirm Cancel: blah');
  //           // navigator['app'].exitApp();
  //         }
  //       },{
  //         text: 'Update',
  //         handler: () => {
  //           window.open('https://play.google.com/store/apps/details?id=com.servicewale.anw', '_system', 'location=yes');
  //           navigator['app'].exitApp();
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  // async forceUpdatePopup(UpdateMessage) {
  //   const alert = await this.alertController.create({
  //     header: 'New Update!',
  //     message: UpdateMessage,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         handler: (blah) => {
  //           console.log('Confirm Cancel: blah');
  //           navigator['app'].exitApp();
  //         }
  //       },{
  //         text: 'Update',
  //         handler: () => {
  //           window.open('https://play.google.com/store/apps/details?id=com.servicewale.anw', '_system', 'location=yes');
  //           navigator['app'].exitApp();
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  getPageContent(){
    this.get('content').subscribe(res=>{
      this.pageContents = res.data
    })
  }


  post (url:any,data:any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '' + url, data);
  }

  get (url:any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '' + url);
  }

  logout (): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'logout')
      .pipe(
        tap(_ => this.log('logout')),
        catchError(this.handleError('logout', []))
      );
  }

  
  // userImage(id,image){
  //   if(image){
  //     return this.baseUrl + '/' +image;
  //   }
  // 	return "assets/user.jpg";
  //   // return 'https://www.mobilfreetv.com/'+url;
  // }

  // userPic(image){
  // 	image.src = "assets/images/dp_placeholder.png"
  // }

  // catImage(image){
  //   image.src = "assets/images/store.png";
  // }

  presentToast(msg:any) {
    // alert(msg)
    this._snackBar.open(msg, 'Ok', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });

    $('.mat-simple-snackbar > span').html(msg).css('color','#fff');
  }
  
  // async presentToastCenter(msg:any) {
  //   const toast = await this.toastController.create({
  //     message: msg,
  //     duration: 4000,
  //     position: 'middle'
  //   });
  //   toast.present();
  //   // this.toast.show(msg, '4000', 'center').subscribe(
  //   //   toast => {
  //   //     console.log(toast);
  //   //   }
  //   // );
  // }

  // async confirmAlert(title,msg) {
  //   const alert = await this.alertController.create({
  //     header: 'Agent Registration!!',
  //     subHeader: title,
  //     message: msg,
  //     buttons: ['OK']
  //   });

  //   await alert.present();
  // }

  // async showPopup(title,msg) {
  //   const alert = await this.alertController.create({
  //     header: title,
  //     message: msg,
  //     buttons: ['OK']
  //   });

  //   await alert.present();
  // }

  // public showLoading() {
  //   this.loadingController.create({
  //     message: '',
  //     cssClass:'custom',
  //     spinner:'dots'
  //   }).then((res) => {
  //     res.present();
  //     setTimeout(()=>{
  //       // this.presentToast("Please check your internet connection!")
  //       this.hideLoading()
  //     },8000)
  //     res.onDidDismiss().then((dis) => {
  //       console.log('Loading dismissed! after 2 Seconds');
  //     });
  //   });
    
  // }

  // public hideLoading() {
  //   setTimeout(()=>{
  //     this.loadingController.dismiss();
  //   },1000)
    
  // }
  
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }

  // locally manage favorite product
  checkProductInfavorite(id:any){
    let favoriteItems = localStorage.getItem('favoriteItems');
    if(favoriteItems){
      let favorite = JSON.parse(favoriteItems);
      let index = favorite.findIndex((ele:any)=>ele == id);
      if(index > -1){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }



  addTofavorite(product_id:any){
    let favoriteItems = localStorage.getItem('favoriteItems');
    if(favoriteItems){ 
      let favoriteList = JSON.parse(favoriteItems);
      let index = favoriteList.findIndex((ele:any)=>ele == product_id);
      if(index > -1){
        favoriteList.splice(index, 1);
        if(favoriteList.length > 0)
        localStorage.setItem('favoriteItems', JSON.stringify(favoriteList));
        else
        localStorage.removeItem('favoriteItems');
      }else{
        favoriteList.push(product_id);
        localStorage.setItem('favoriteItems', JSON.stringify(favoriteList));
      }

    }else{
      let favoriteList = [];
      favoriteList.push(product_id);
      localStorage.setItem('favoriteItems', JSON.stringify(favoriteList));
    }
  }

  getfavoriteItems(){
    let favoriteItems = localStorage.getItem('favoriteItems');
    if(favoriteItems){
      let favorite = JSON.parse(favoriteItems);
      return favorite;
    }else{
      return [];
    } 
  }

  getTotalAmount(){
    
    return this.discountPrice !=0 ? this.discountPrice : this.getCartSubTotal();
  }
  /** localy manage cart on local storage start */
  getCartSubTotal(){
    
      let cartItems = localStorage.getItem('cartItems');
      if(cartItems){
        let cart = JSON.parse(cartItems);
        let sub_total = 0;
        cart.forEach((ele: any)=>{
          sub_total = sub_total + parseFloat(ele.total_price);
        })
        return sub_total;
      }else{
        return 0 ;
      } 
    
    
  }

  getCartCount(){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){
      let cart = JSON.parse(cartItems);
      return cart.length;
    }else{
      return 0;
    } 
  }

  getCartItems(){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){
      let cart = JSON.parse(cartItems);
      return cart;
    }else{
      return [];
    } 
  }

  addToCart(product:any){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){ 
      let cart = JSON.parse(cartItems);
      // product['quantity'] = 1;
      // console.log(product);
      cart.push(product);
      localStorage.setItem('cartItems', JSON.stringify(cart));
    }else{
      // console.log(product);
      // product['quantity'] = 1;
      let cart = [];
      cart.push(product);
      localStorage.setItem('cartItems', JSON.stringify(cart));
    }
  }

  checkProductInCart(id:any){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){
      let cart = JSON.parse(cartItems);
      let index = cart.findIndex((ele:any)=>ele.id == id);
      if(index > -1){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  removeProductFromCart(id:any){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){
      let cart = JSON.parse(cartItems);
      let index = cart.findIndex((ele:any)=>ele.id == id);
      if(index > -1){
        cart.splice(index, 1);
        if(cart.length > 0)
          localStorage.setItem('cartItems', JSON.stringify(cart));
        else
          localStorage.removeItem('cartItems');
      }

    }
  }


  // action = decrease | increase
  updateCart(product:any,action:any){
    let cartItems = localStorage.getItem('cartItems');
    if(cartItems){
      let cart = JSON.parse(cartItems);
      let index = cart.findIndex((ele:any)=>ele.id == product.id);
      if(index > -1){
        // var product :any = cart[index];
        if(action == "increase"){
            cart[index]['quantity'] =  ++product['quantity'];
            product['total_price'] = (parseFloat( cart[index]['quantity']) * parseFloat( cart[index]['price'])) + parseFloat( cart[index]['add_ons_total_price']);
            cart[index]['total_price'] = product['total_price'];
            console.log(cart);
            localStorage.setItem('cartItems', JSON.stringify(cart));
        }else{  // decrease
            if(cart[index]['quantity'] > 1){
              cart[index]['quantity'] =  --product['quantity'];
              product['total_price'] = (parseFloat( cart[index]['quantity']) * parseFloat( cart[index]['price'])) + parseFloat( cart[index]['add_ons_total_price']);
              cart[index]['total_price'] = product['total_price'];  
              console.log(cart);
              localStorage.setItem('cartItems', JSON.stringify(cart));
            }else{
              cart.splice(index, 1);
              if(cart.length > 0)
                localStorage.setItem('cartItems', JSON.stringify(cart));
              else
                localStorage.removeItem('cartItems');
            }
           
        }
      }

    }
  }
  /** localy manage cart on local storage end */


  /* get  user lat long start*/
  getUserlatLng(){
    let userlatlng = {"lat":"28.7301207","lng":"77.0900743"};
    return userlatlng;
  }
  /* get  user lat long end*/
}