import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CommonService } from 'src/app/common.service';
export interface DialogData {
  content: any;
}
@Component({
  selector: 'dialog-detail',
  templateUrl: './dialog-detail.html',
  styleUrls: ['./dialog-detail.component.scss'],
})
export class DialogDetail{
  declarationType: string='';
  selectBranch:string='';
  declaration: string[] = ['Self', 'Spouse', 'Dependent Parent ', 'Dependent Child'];
  branches: string[] = [
    'HDFC Bank, Shop No 9A and 9B, Hyper tower, Greater City, Malad (W) Mindscape, Maharashtra', 
    'HDFC Bank, Ground Floor, Office No. 2, Rajhans MIDC Complex, Malad (E), Maharashtra', 
    'HDFC Bank, Ground Floor, Victory Hi-tech, Near Anand Nagar, Malad (E), Maharashtra', 
    ];
  content: any;
  constructor(
    public dialogRef: MatDialogRef<DialogDetail>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private router: Router, private commonService: CommonService) {
    this.content = this.data.content;

    // this.isFilter = this.data.type;
    // this.isFilter = this.data.type;
    // this.segment = this.data.segment;
    var x = document.querySelectorAll(".cdk-overlay-pane");
    x.forEach(ele=>{
      ele.classList.add(this.commonService.themeColors[this.commonService.theme]);
    })
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dismissWithAction(popup:any){
    this.dialogRef.close(popup);
  }

  navigate(page: any){
    this.dialogRef.close();
    this.router.navigateByUrl(page);
  }

}
