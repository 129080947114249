import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
declare var $: any;''
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // {'dark-primary':theme == '3'}
  theme: any = '3';
  themeColors:any=['','default', 'dark-theme','dark-primary','colored'];
  truedataToken: any;
  expire_time: any = 0;
  nifty50: any;
  niftyBank: any;
  constructor() {
    this.getTrueDataToken()
  }

  yesterDayDate(){
    var $today: any = new Date();
    var $yesterday: any = new Date($today);
    $yesterday.setDate($today.getDate() - 1); //setDate also supports negative values, which cause the month to rollover.
    // Then just apply the formatting code you wrote:

    var $dd = $yesterday.getDate();
    var $mm = $yesterday.getMonth()+1; //January is 0!

    var $yyyy = $yesterday.getFullYear();
    if($dd<10){$dd='0'+$dd} if($mm<10){$mm='0'+$mm} 
    return $yesterday = $yyyy+'-'+$mm+'-'+$dd;
  }

  getTrueDataToken(){
    var self = this;
    var settings = {
      "url": "https://auth.truedata.in/token",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "username": "tdwsp419",
        "password": "anand@419",
        "grant_type": "password"
      }
    };

    $.ajax(settings).done(function (response: any) {
      console.log(response);
      self.truedataToken = response.access_token
      self.expire_time = Math.floor(Date.now() / 1000) + response.expires_in;


      var settings = {
        "url": "https://history.truedata.in/getlastnticks?symbol=NIFTY 50&bidask=1&response=json&nticks=1&interval=tick",
        "method": "GET",
        "timeout": 0,
        "headers": {
          "Authorization": "Bearer "+response.access_token
        },
      };

      $.ajax(settings).done(function (response: any) {
        console.log(response);
        self.nifty50 = response.Records[0][1];
      });

      var settings = {
        "url": "https://history.truedata.in/getlastnticks?symbol=NIFTY BANK&bidask=1&response=json&nticks=1&interval=tick",
        "method": "GET",
        "timeout": 0,
        "headers": {
          "Authorization": "Bearer "+response.access_token
        },
      };

      $.ajax(settings).done(function (response: any) {
        console.log(response);
        self.niftyBank = response.Records[0][1];
      });
    });
  }



  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }
}


